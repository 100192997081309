<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- Currency LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class>Daily Cost Report List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
              <!-- <div class="mr-2">Filter:</div>
              <div class="mr-3">
                <vs-dropdown>
                  <a class="flex items-center" href>
                    {{ SelectOption}}
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="allCurrency('All')">All</vs-dropdown-item>
                    <vs-dropdown-item @click="activeCurrency('Active')">Active</vs-dropdown-item>
                    <vs-dropdown-item @click="inactiveCurrency('Inactive')">Inactive</vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>-->

              <vs-input
                class="mb-4 md:mb-0 mr-4 ml-3"
                v-model="searchQuery"
                @input="getDprList22('1')"
                placeholder="Search..."
              />
              <vx-tooltip text="Export Details" v-if="showExcel" class="px-3">
                <a
                  :href="
                    excelurl +
                      `/v2/dpr/excelParticulars/download?token=${temp_token}&search=${searchQuery}&project_id=${formpayload.project_id}&dateFrom=${formpayload.fromDate}&dateTo=${formpayload.toDate}&organization_id=${organization_id}`
                  "
                  target="_blank"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export Details</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>

              <vx-tooltip text="Export Excel" v-if="formpayload.hasOwnProperty('fromDate')">
                <a
                  :href="
                    excelurl +
                      `/v2/dpr/excel/download?token=${temp_token}&search=${searchQuery}&project_id=${formpayload.project_id}&dateFrom=${formpayload.fromDate}&dateTo=${formpayload.toDate}&organization_id=${organization_id}`
                  "
                  target="_blank"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
              <!-- <vs-button type="filled" class="mr-3">Save</vs-button>   colResizeDefault="shift"-->
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="budgetList"
          rowSelection="multiple"
          :suppressRowClickSelection="true"
          :singleClickEdit="true"
          :animateRows="true"
          :icons="icons"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>

    <!-- SAVE AS DRAFT AND SAVE POPUP -->
    <vs-popup title="Final Save" :active.sync="changesSave">
      <div class="text-center">
        <h5>Do you want to save</h5>
        <!-- SUBMIT BUTTON -->
        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <!-- @click="onSubmitPO()" -->
            <!-- <vs-button color="success" type="filled" class="mr-3"
            >Save as Draft</vs-button-->

            <!-- @click="privStatus()" -->
            <vs-button color="danger" type="filled">Final Save</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- FINAL SAVE POPUP -->
    <vs-popup title="Save Changes" :active.sync="FinalSaveReason" button-close-hidden>
      <div class="text-center">
        <h5>Reason For Change</h5>
        <!-- SUBMIT BUTTON -->
        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <vs-textarea v-model="reason" class="mb-4" rows="4" />
            <!-- @click="onSubmitPO()" -->
            <vs-button color="success" type="filled" @click="submitAndSave" class="mr-3">Submit</vs-button>
            <!-- @click="privStatus()" -->
            <vs-button color="danger" @click="closeModalnew">Cancel</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <div class>
      <vs-popup fullscreen class="dprTestTable" :title="PopupTitle" :active.sync="popupActiveTest">
        <!-- TEST TABLE FORM -->
        <div class="contract-Table">
          <b-table
            class="border mb-0"
            :sticky-header="stickyHeader"
            :no-border-collapse="noCollapse"
            responsive
            :items="particularItems"
            :fields="particularfields"
          >
            <!-- We are using utility class `text-nowrap` to help illustrate horizontal scrolling -->
            <!-- ADD BUTTON-->
            <template v-slot:head(add)="data">
              <span class="text-center table-edit-icon" @click="addParticulars">
                <i class="text-center material-icons bg-transparent">add</i>
              </span>
            </template>
            <template v-slot:cell(add)="data">
              <span
                class="text-center table-edit-icon"
                @click="removeParticulars(data.index,data.item)"
                v-if="(!data.item.hasOwnProperty('particular_id') && particularItems.length>1)"
              >
                <i class="text-center material-icons bg-transparent">remove</i>
              </span>
            </template>
            <!-- Particular  -->
            <template v-slot:cell(particular_name)="data">
              <vs-input
                name="Particular"
                class="w-full asterisk-icon"
                v-model="particularItems[data.index]['particular_name']"
                :disabled="particularItems[data.index]['project_type']==2"
              />
            </template>
            <!-- Unit Rate % -->
            <template v-slot:cell(unit_rate)="data">
              <vs-input
                name="Unit Rate"
                type="number"
                class="w-full asterisk-icon"
                v-model="particularItems[data.index]['unit_rate']"
                @input="calculationAmount(data.item,data.index)"
                :disabled="particularItems[data.index]['project_type']==2"
              />
            </template>
            <!-- No. of Unit -->
            <template v-slot:cell(unit_no)="data">
              <vs-input
                name="No. of Unit"
                type="number"
                class="w-full asterisk-icon"
                v-model="particularItems[data.index]['unit_no']"
                @input="calculationAmount(data.item,data.index)"
                :disabled="particularItems[data.index]['project_type']==2"
              />
            </template>
            <!-- Time /Shift -->
            <template v-slot:cell(time_shift)="data">
              <vs-input
                name="Time /Shift"
                type="number"
                class="w-full asterisk-icon"
                v-model="particularItems[data.index]['time_shift']"
                @input="calculationAmount(data.item,data.index)"
                :disabled="particularItems[data.index]['project_type']==2"
              />
            </template>
            <!-- Other cost -->
            <template v-slot:cell(other_cost)="data">
              <vs-input
                type="number"
                name="Other cost"
                class="w-full asterisk-icon dateInput"
                v-model="particularItems[data.index]['other_cost']"
                @input="calculationAmount(data.item,data.index)"
                :disabled="particularItems[data.index]['project_type']==2"
              />
            </template>
            <!-- Amount -->
            <template v-slot:cell(amount)="data">
              <vs-input
                type="number"
                class="w-full asterisk-icon dateInput"
                v-model="particularItems[data.index]['amount']"
                :disabled="true"
              />
            </template>
          </b-table>
        </div>
        <div class="text-right pr-4">
          <h6>Total Amount : {{totalDprValue}}</h6>
        </div>
        <div class="vx-col mx-auto text-center mt-5">
          <!-- @click.prevent="submitForm" -->
          <!-- :disabled="count.length != 2" -->

          <vs-button
            type="filled"
            class="hover-btn mr-3"
            @click.prevent="addNewDpr(saveCurrentAllValue,1)"
          >Submit</vs-button>
          <!-- @click.prevent="clearForm" -->
          <vs-button color="warning" type="border" @click.prevent="closeModalnewForm">Cancel</vs-button>
        </div>
      </vs-popup>

      <vs-popup
        :button-close-hidden="false"
        class="holamundo p-2 assigneePopup"
        title="Select Work Type"
        :active.sync="addUserLevel"
      >
        <div class="text-center">
          <h5>Select Work Type</h5>

          <div class="vx-row mt-5">
            <div class="vx-col mx-auto w-100">
              <multiselect
                label="project_title"
                class="w-full mb-5"
                track-by="work_type_id"
                v-model="workTypeAdd"
                :options="worktypelistData"
                :searchable="true"
                :allow-empty="false"
                :multiple="false"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{
                  option.project_title
                  }}
                </template>
              </multiselect>
              <vs-button
                color="success"
                type="filled"
                class="mr-3"
                @click="addNewDpr(saveCurrentAllValue,1)"
                :disabled="workTypeAdd==''"
              >Save</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
const VxTour = () => import("@/components/VxTour.vue");
import DPRService from "@/services/dprService";
import { BASEURL } from "@/config/index.js";
import moment from "moment";
import SearchService from "@/services/searchService.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import WorkTypeService from "@/services/workTypeService.js";
import { CustomNumericCellEditor } from "@/helper/numericCollection.js";
import dprFinalButton from "./dprFInalSave.vue";
import dprFInalSaveVue from "./dprFInalSave.vue";
import dprDate from "./dprDate";
import viewDprReason from "./viewDprReason.vue";

export default {
  components: {
    AgGridVue,
    VxTour,

    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>KOZO</span>`,
    },
  },
  props: ["listType"],
  data() {
    return {
      workTypeAdd: "",
      addUserLevel: false,
      popupActiveTest: false,
      json_field: {
        " Budget code": "budget_code_num",
        " Category": "category_name",
        " Subcategory": "sub_category_name",
        " Budget": "budget_amount",
        "Work Type": "project_title",
        "Start Date": "start_date",
        "End Date": "end_date",
        "Total Budget": "total_budget",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      dprlist_data: [],
      frameworkComponents: null,
      array_check: {},
      formpayload: {},
      excelurl: BASEURL,
      totalPages: 0,
      params: null,
      overlayLoadingTemplate: null,
      archive: this.listType,
      reason: "",
      changesSave: false,
      FinalSaveReason: false,
      budgetList: [],
      finalArray: [],
      dprlist: [],
      icons: null,
      tempColdefs: [],
      SelectOption: "Select Option",
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      gridOptions: {
        //onCellEditingStopped: this.onTableCellEditorNewStoppped,
        //  onCellEditingStarted: this.onTableCellStarted,
        onCellEditingStarted: this.onTableCellEditorNew,
        components: {
          numericCellEditor: CustomNumericCellEditor(),
        },
      },
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        suppressMenu: true,
      },
      worktypelist: [],
      worktypelistData: [],
      fromDate: null,
      toDate: null,
      filterValue: "",
      options: ["Active", "Inactive"],
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      getArrayDate: [],
      columnDefs: [],
      old_dpr_value: "",
      highestTypeDate: "",
      lowestTypeDate: "",
      showExcelButton: false,
      userLogin: window.localStorage.getItem("UserRole"),
      countTab: 0,
      getAllDateArray: [],
      particularfields: [
        {
          key: "Add",
          label: "",
          sortable: true,
        },
        {
          key: "particular_name",
          label: "Particular",
          sortable: true,
        },
        {
          key: "unit_rate",
          label: "Unit Rate",
          sortable: true,
        },
        {
          key: "unit_no",
          label: "No. of Unit",
          sortable: true,
        },
        {
          key: "time_shift",
          label: "Time /Shift",
          sortable: true,
        },
        {
          key: "other_cost",
          label: "Other cost",
          sortable: true,
        },
        {
          key: "amount",
          label: "Amount",
          sortable: true,
        },
      ],
      countError:0,
      particularItems: [
        {
          add: "1",
          particular_name: "",
          unit_rate: 0,
          unit_no: 0,
          time_shift: 0,
          other_cost: 0,
          amount: 0,
          project_type: 1,
          show_reason: 0,
        },
      ],

      saveCurrentAllValue: {},
      PopupTitle: "ATL 20-09-20",
      showExcel: false,
    };
  },
  beforeMount() {
    this.getWorkTypeList();

    eventBus.$on("refreshTableDPR", (e) => {
      this.formpayload = e;
      this.budgetList = [];
      this.columnDeval();

      this.getDprList22(this.currentPage);
    });

    this.columnDeval();

    // this.setColums();
    //  this.components = { datePicker: getDatePicker() }
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    organization_id() {
      return localStorage.getItem("OrganizationID");
    },
    totalDprValue() {
      let amountq1 = 0;
      this.particularItems.map((x) => {
        amountq1 = parseFloat(amountq1) + parseFloat(x.amount);
      });
      return parseFloat(amountq1).toFixed(2);
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getDprList22(val);
          return val;
        } else return 1;
      },
    },
  },

  methods: {
    calculationAmount(data, index) {
      let totalAmount =
        parseFloat(data.unit_rate) *
          parseFloat(data.unit_no) *
          parseFloat(data.time_shift) +
        parseFloat(data.other_cost);
      //  console.log(totalAmount,data);
      this.particularItems[index]["amount"] = totalAmount;
    },
    removeParticulars(index, data) {
      if (!data.hasOwnProperty("particular_id")) {
        this.particularItems.splice(index, 1);
      }
    },

    addParticulars() {
      let obj = {
        add: "1",
        particular_name: "",
        unit_rate: 0,
        unit_no: 0,
        time_shift: 0,
        other_cost: 0,
        amount: 0,
        project_type: 1,
        show_reason: 0,
      };
      this.particularItems.unshift(obj);
    },

    onTableCellEditorNew(event) {
      this.saveCurrentAllValue = event;
      console.log(event);
      if (event.colDef.field == "project_title") {
        this.workTypeAdd = "";
        this.addUserLevel = true;

        this.gridApi.stopEditing();
        return false;
      }

      if (event.data[event.colDef.field + "_dpr_id"] != "") {
        this.$vs.loading();
        let filters = {
          project_id: event.data.project_id,
          dpr_id: event.data[event.colDef.field + "_dpr_id"],
        };
        //  console.log(filters);
        //  return false;
        this.popupActiveTest = true;

        DPRService.getParticularList(filters)
          .then((response) => {
            const { data } = response;
            // console.log(data.data, "draftData");
            if (data.data.length > 0) {
              this.particularItems = data.data;
            } else {
              this.particularItems = [
                {
                  add: "1",
                  particular_name: "",
                  unit_rate: 0,
                  unit_no: 0,
                  time_shift: 0,
                  other_cost: 0,
                  amount: 0,
                  project_type: 1,
                  show_reason: 0,
                },
              ];
            }
            this.$vs.loading.close();
            this.gridApi.stopEditing();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.particularItems = [
          {
            add: "1",
            particular_name: "",
            unit_rate: 0,
            unit_no: 0,
            time_shift: 0,
            other_cost: 0,
            amount: 0,
            project_type: 1,
            show_reason: 0,
          },
        ];
        //console.log(event);

        // console.log(this.saveCurrentAllValue);
        this.PopupTitle =
          this.saveCurrentAllValue.data.budget_code_num +
          " ( " +
          this.saveCurrentAllValue.colDef.field +
          " )";
        this.popupActiveTest = true;
        this.gridApi.stopEditing();
      }
    },

    addNewDpr(data, id) {
      let count = 0;
      this.particularItems.map((x) => {
        if (x.hasOwnProperty("particular_id")) {
          if (x.oldAmount > 0 && x.amount != x.oldAmount) {
            count++;
          }
        }
      });

      if (count > 0 && id == 1) {
        this.FinalSaveReason = true;
        return false;
      }

      let event = data;
      this.$vs.loading();
      // console.log(event,data);
      // return false;

      //    console.log(event, this.countTab);
      this.countTab = 1;

      let colIndex = this.columnDefs.findIndex((x) => {
        return x.field == event.colDef.field;
      });

      // if (
      //   event.data[event.colDef.field] == "" ||
      //   event.data[event.colDef.field] == null ||
      //   this.old_dpr_value.value == event.value
      // ) {
      //   // console.log(object);
      //   this.budgetList[event.rowIndex][
      //     event.colDef.field
      //   ] = this.old_dpr_value.value;
      //   this.gridOptions.api.refreshView();
      //   return false;
      // }

      // if (
      //   event.data[event.colDef.field + "_save_status"] == "1" &&
      //   event.colDef.field.includes("-")
      // ) {
      //   this.FinalSaveReason = true;
      //   return false;
      // }

      let work_type_id = 0;
      //console.log(object);

      if (!event.colDef.field.includes("-")) {
        work_type_id = this.workTypeAdd.work_type_id;

        this.budgetList[event.rowIndex][
          "project_title"
        ] = this.workTypeAdd.project_title;
      } else {
        this.budgetList[event.rowIndex][
          event.colDef.field
        ] = this.totalDprValue;
      }

      let payload = {
        dpr_id: event.colDef.field.includes("-")
          ? event.data[event.colDef.field + "_dpr_id"]
          : event.data.dpr_id,
        type: event.colDef.field.includes("-") ? 2 : 1,
        project_id: event.data.project_id,
        budget_id: event.data.budget_id,
        dpr_reason: this.reason,
        start_date: event.data.start_date,
        end_date: event.data.end_date,
        work_type_id: work_type_id,
        type_date: event.colDef.field.includes("-") ? event.colDef.field : 0,
        save_status: 1,
        dpr_value: this.totalDprValue,
        particularItems: this.particularItems,
      };

      console.log(payload);

      DPRService.addDpr(payload)
        .then((response) => {
          const { data } = response;
          console.log(data);
          this.popupActiveTest = false;
          if (data.hasOwnProperty("dpr_id")) {
            if (event.colDef.field.includes("-")) {
              this.budgetList[event.rowIndex][event.colDef.field + "_dpr_id"] =
                data.dpr_id;
            } else {
              this.budgetList[event.rowIndex]["dpr_id"] = data.dpr_id;
            }
          }

          this.budgetList[event.rowIndex][
            event.colDef.field + "_save_status"
          ] = 1;

          if (event.colDef.field.includes("-")) {
            this.budgetList[event.rowIndex]["total_budget"] = 0;
            Object.keys(this.budgetList[event.rowIndex]).map((x) => {
              if (
                x.includes("-") &&
                this.budgetList[event.rowIndex][x] != "" &&
                !x.includes("_dpr_id") &&
                !x.includes("_save_status") &&
                this.budgetList[event.rowIndex][x] != "-"
              ) {
                //this.budgetList[event.rowIndex][x]=this.totalDprValue
                console.log(this.budgetList[event.rowIndex][x], "fsdfsd", x);
                this.budgetList[event.rowIndex]["total_budget"] =
                  parseFloat(this.budgetList[event.rowIndex]["total_budget"]) +
                  parseFloat(this.budgetList[event.rowIndex][x]);
                //
              }
              event.data["total_budget"] = this.budgetList[event.rowIndex][
                "total_budget"
              ];
            });
          }
          if (this.FinalSaveReason) {
            this.FinalSaveReason = false;
            this.popupActiveTest = false;
          }
          this.reason = "";
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Data Updated Successfully",
            text: "",
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });

          this.addUserLevel = false;
          this.gridOptions.api.refreshView();
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    onTableCellEditor(event) {
      // return false;
      console.log(event, this.countTab);
      this.countTab = 1;
      //  this.countTab = 1;

      // this.gridApi.setFocusedCell(event.rowIndex, "make");

      let colIndex = this.columnDefs.findIndex((x) => {
        return x.field == event.colDef.field;
      });

      if (
        event.data[event.colDef.field] == "" ||
        event.data[event.colDef.field] == null ||
        this.old_dpr_value.value == event.value
      ) {
        // console.log(object);
        this.budgetList[event.rowIndex][
          event.colDef.field
        ] = this.old_dpr_value.value;
        this.gridOptions.api.refreshView();
        return false;
      }

      if (
        event.data[event.colDef.field + "_save_status"] == "1" &&
        event.colDef.field.includes("-")
      ) {
        this.FinalSaveReason = true;
        return false;
      }

      this.budgetList[event.rowIndex][event.colDef.field] =
        event.data[event.colDef.field];

      let work_type_id = 0;

      if (!event.colDef.field.includes("-")) {
        work_type_id = this.worktypelistData.filter((x) => {
          return x.project_title == event.data[event.colDef.field];
        })[0]["work_type_id"];
      }

      this.budgetList[event.rowIndex][event.colDef.field] =
        event.data[event.colDef.field];

      let payload = {
        dpr_id: event.colDef.field.includes("-")
          ? event.data[event.colDef.field + "_dpr_id"]
          : event.data.dpr_id,
        type: event.colDef.field.includes("-") ? 2 : 1,
        project_id: event.data.project_id,
        budget_id: event.data.budget_id,
        dpr_reason: event.data.dpr_reason,
        start_date: event.data.start_date,
        end_date: event.data.end_date,
        work_type_id: work_type_id,
        type_date: event.colDef.field.includes("-") ? event.colDef.field : 0,
        save_status: this.userLogin.includes("Admin") ? 1 : 2,
        dpr_value: event.colDef.field.includes("-")
          ? event.data[event.colDef.field]
          : 0,
      };
      // console.log(payload);
      DPRService.addDpr(payload)
        .then((response) => {
          const { data } = response;
          this.popupActiveTest = false;
          if (data.hasOwnProperty("dpr_id")) {
            if (event.colDef.field.includes("-")) {
              this.budgetList[event.rowIndex][event.colDef.field + "_dpr_id"] =
                data.dpr_id;
            } else {
              this.budgetList[event.rowIndex]["dpr_id"] = data.dpr_id;
            }
          }
          this.budgetList[event.rowIndex][
            event.colDef.field + "_save_status"
          ] = this.userLogin.includes("Admin") ? 1 : 2;
          if (event.colDef.field.includes("-")) {
            this.budgetList[event.rowIndex]["total_budget"] = 0;
            Object.keys(this.budgetList[event.rowIndex]).map((x) => {
              if (
                x.includes("-") &&
                this.budgetList[event.rowIndex][x] != "" &&
                !x.includes("_dpr_id") &&
                !x.includes("_save_status") &&
                this.budgetList[event.rowIndex][x] != "-"
              ) {
                console.log(this.budgetList[event.rowIndex][x], "fsdfsd", x);
                this.budgetList[event.rowIndex]["total_budget"] =
                  parseFloat(this.budgetList[event.rowIndex]["total_budget"]) +
                  parseFloat(this.budgetList[event.rowIndex][x]);
                //
              }
              event.data["total_budget"] = this.budgetList[event.rowIndex][
                "total_budget"
              ];
            });

            // this.$vs.notify({
            //   title: "Data Updated Successfully",
            //   text: "",
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
            // this.getExcel("1");

            this.gridOptions.api.refreshView();
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    // onTableCellEditorNewStoppped(event){
    // this.popupActiveTest=false;

    // },

    onTableCellStarted(event) {
      // console.log(event, "fsfsdfsdf");
      console.log(event.colDef.field);
      if (this.countTab == 1) {
        let x = this.budgetList[event.rowIndex];
        let dateCheck = "";
        let count = 0;
        let mainCountNew = 0;

        let indexCheck = this.getAllDateArray.indexOf(event.colDef.field);

        if (indexCheck < 0) {
          this.countTab = 0;
          return false;
        }

        this.getAllDateArray.map((z, index) => {
          if (index >= indexCheck) {
            if (this.budgetList[event.rowIndex][z] != "-") {
              if (count == 0) {
                count = count + 1;
                dateCheck = z;
              }
            }
          }
        });

        // if (count == 0) {
        //   console.log("fsdfdsfdsf");
        //   for (let k = 0; k <= this.budgetList.length; k++) {
        //     if (k >= event.rowIndex) {
        //       let budget = this.budgetList[k];
        //       Object.key(budget).map(y => {
        //         if (
        //           y.includes("-") &&
        //           !y.includes("_dpr_id") &&
        //           !y.includes("_save_status") &&
        //           y[x] != "-"
        //         ) {
        //           console.log(y);
        //           if (count == 0) {
        //             dateCheck = y;
        //             mainCountNew = k;
        //             count = count + 1;
        //           }
        //         }
        //       });
        //     }

        //     if (count > 0) {
        //       break;
        //     }
        //   }
        // }

        // Object.keys(x).map(y => {
        //   if (
        //     y.includes("-") &&
        //     !y.includes("_dpr_id") &&
        //     !y.includes("_save_status") &&
        //     y[x] != "-" &&
        //     event.colDef.field != y
        //   ) {

        // if (count == 0) {
        //   console.log(y);
        //   count = count + 1;
        //   dateCheck = y;
        // }
        //   }
        // });
        //  this.gridOptions.api.tabToNextCell();

        this.gridOptions.api.startEditingCell({
          rowIndex: mainCountNew > 0 ? mainCountNew : event.rowIndex,
          colKey: dateCheck,
        });
        this.countTab = 0;
      }
      this.old_dpr_value = "";
      this.old_dpr_value = event;
    },
    submitAndSave() {
      if (this.reason == "") {
        this.$vs.notify({
          title: "",
          text: "Please Enter Reason",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
        return false;
      }

      this.addNewDpr(this.saveCurrentAllValue, 2);
      // let rowIndexNew = this.old_dpr_value.rowIndex;
      // let fieldname = this.old_dpr_value.colDef;
      // let payload = {
      //   dpr_id: this.budgetList[rowIndexNew][fieldname.field + "_dpr_id"],
      //   type: 2,
      //   project_id: this.old_dpr_value.data.project_id,
      //   budget_id: this.old_dpr_value.data.budget_id,
      //   old_dpr_value: this.old_dpr_value.value,
      //   dpr_reason: this.reason,
      //   work_type_id: 0,
      //   type_date: this.old_dpr_value.colDef.field,

      //   save_status: 1,
      //   dpr_value: this.budgetList[rowIndexNew][this.old_dpr_value.colDef.field]
      // };

      // DPRService.addDpr(payload)
      // .then(response => {
      //   const { data } = response;
      //   this.$vs.notify({
      //     title: "",
      //     text: "Data Updated Successfully",
      //     iconPack: "feather",
      //     icon: "check_box",
      //     color: "success"
      //   });
      //   this.reason = "";
      //   let rowIndex = this.old_dpr_value.rowIndex;
      //   if (this.old_dpr_value.colDef.field.includes("-")) {
      //     this.budgetList[rowIndex]["total_budget"] = 0;

      //     Object.keys(this.budgetList[rowIndex]).map(x => {
      //       if (
      //         x.includes("-") &&
      //         this.budgetList[rowIndex][x] != "" &&
      //         !x.includes("_dpr_id") &&
      //         !x.includes("_save_status") &&
      //         this.budgetList[rowIndex][x] != "-"
      //       ) {
      //         console.log(x);
      //         this.budgetList[rowIndex]["total_budget"] =
      //           parseFloat(this.budgetList[rowIndex]["total_budget"]) +
      //           parseFloat(this.budgetList[rowIndex][x]);
      //         //
      //       }
      //     });
      //     this.FinalSaveReason = false;
      //     this.getExcel(null);
      //     this.gridOptions.api.refreshView();
      //     // var params = { force: isForceRefreshSelected() };
      //     // this.gridApi.refreshCells(params);
      //   }
      // })
      // .catch(error => {
      //   this.$vs.notify({
      //     title: "Data Not Found",
      //     text: error.Message,
      //     iconPack: "feather",
      //     icon: "check_box",
      //     color: "warning"
      //   });
      // });
    },
    closeModalnewForm() {
      this.popupActiveTest = false;
    },
    closeModalnew() {
      // let index = this.old_dpr_value.rowIndex;
      // let fieldName = this.old_dpr_value.colDef.field;

      // this.budgetList[index][fieldName] = this.old_dpr_value.value;
      // this.gridOptions.api.refreshView();
      this.FinalSaveReason = false;
    },
    StartDateEndDate(event) {
      console.log(event);
    },
    cellEditorDisable(params) {
      if (params.node.__objectId == 1 && params.node.id == "0") {
        return false;
      } else if (
        params.node.data[params.colDef.field] == "-" ||
        params.node.data[params.colDef.field] == "button"
      ) {
        return false;
      } else {
        return true;
      }
    },
    getWorkTypeList: function (currentPage) {
      let filter = {
        page: 0,
      };
      if (this.archive == "archive") {
        filter["archive"] = true;
      }
      WorkTypeService.getAllWorkType(filter)
        .then((response) => {
          const { data } = response;
          if (!data.err) {
            //   console.log(data);

            if (data.data.length > 0 && data.hasOwnProperty("data")) {
              this.worktypelistData = data.data.filter((x) => {
                return x.work_type_active == 1;
              });
              data.data.map((x) => {
                if (x.work_type_active == 1) {
                  this.worktypelist.push(x.project_title);
                }
              });
            }
            // this.totalPages = data.pagination.last_page;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    columnDeval() {
      // this.frameworkComponents = { genderCellRenderer: GenderCellRenderer };
      this.columnDefs = [
        {
          headerName: " Budget code",
          field: "budget_code_num",
          filter: false,
          width: 120,
          pinned: "left",
          // onCellValueChanged: cellValueChanged()
        },
        {
          headerName: "Category",
          field: "category_name",
          filter: false,
          width: 150,
          pinned: "left",
        },

        {
          headerName: "Subcategory",
          field: "sub_category_name",
          filter: false,
          width: 150,
          pinned: "left",
        },
        {
          headerName: "Budget",
          field: "budget_amount",
          filter: false,
          width: 120,
          pinned: "left",
          hide: this.userLogin.includes("Admin") ? false : true,
          cellRenderer: function (params) {
            return `${params.data.budget_amount.toLocaleString("en-IN")}`;
          },
        },

        {
          headerName: "Work Type",
          field: "project_title",

          editable: this.cellEditorDisable,
          cellEditor: "numericCellEditor",
          cellRenderer: function (params) {
            if (params.rowIndex == 0) {
              return "";
            }
            if (
              params.data.project_title == "" ||
              params.data.project_title == null
            ) {
              return "Select WorkType";
            } else {
              return (
                "<span>" +
                params.data.project_title +
                "<i class='material-icons float-right'>edit</i></span>"
              );
            }
          },
        },

        {
          headerName: "Start Date",
          field: "start_date",
          filter: false,
          width: 220,
          // cellEditor: 'datePicker',
          cellRendererFramework: dprDate,
        },

        {
          headerName: "End Date",
          field: "end_date",
          filter: false,
          width: 220,
          cellRendererFramework: dprDate,
        },

        {
          headerName: "",
          field: "",
          filter: false,
          width: 100,
          cellRendererFramework: viewDprReason,
        },
      ];
    },

    getDates(startDate, stopDate) {
      var dateArray = [];
      var currentDate = moment(startDate);
      var stopDate = moment(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format("DD-MM-YYYY"));
        currentDate = moment(currentDate).add(1, "days");
      }
      return dateArray;
    },
    cellValueChanged: function () {
      // alert("hiiii");
    },
    onRowDataChanged(e) {
      console.log("e :");
    },
    onChangeStatus: function () {
      console.log("object");
    },

    // updateSearchQuery(val) {

    // },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getSelectedRows() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      const selectedDataStringPresentation = selectedData
        .map((node) => node.make + " " + node.model)
        .join(", ");
    },
    getDprList22: function (currentPage) {
      this.SelectOption = "Active";

      if (!this.formpayload.hasOwnProperty("fromDate")) {
        return false;
      }

      let filter = {
        page: currentPage,
        ...this.formpayload,
        search: this.searchQuery,
      };

      if (this.userLogin.includes("Admin")) {
        filter["saveStatus"] = 1;
      }
      this.$vs.loading();
      // console.log(filter);
      // return false;
      DPRService.getDprList(filter)
        .then((response) => {
          const { data } = response;
          this.columnDeval();

          if (data.Status == false) {
            this.budgetList = [];
            this.$vs.loading.close();
            this.showExcelButton = false;
          } else if (data.data.hasOwnProperty("budgetRecords")) {
            this.budgetList = [];
            this.dprlist = [];
            this.getAllDateArray = [];

            this.showExcel = data.showExcel;
            this.budgetList = data.data.budgetRecords.data;
            this.dprlist = data.data.dprRecords;
            //  this.showExcelButton = false;
            this.totalPages = data.data.budgetRecords.pagination.last_page;
            //console.log("==", filter.fromDate1, "===", filter.toDate1, "==");
            let array = this.getDates(
              new Date(filter.fromDate1),
              new Date(filter.toDate1)
            );

            this.getAllDateArray = array;

            this.budgetList.map((y, index) => {
              let newArrayCheck = [];
              if (y.budget_start_date == "" || y.budget_start_date == null) {
                y["start_date"] = "";
                y["end_date"] = "";
              } else {
                y["start_date"] = y.budget_start_date;
                y["end_date"] = y.budget_end_date;
                newArrayCheck = this.getDates(
                  new Date(y.budget_start_date),
                  new Date(y.budget_end_date)
                );
              }

              // console.log(newArrayCheck, "fsdfdsfdsfdsf");

              y["start_date1"] = filter.fromDate1;
              y["end_date1"] = filter.toDate1;

              y["total_budget"] = 0;
              y["dpr_reason"] = "";

              // y["save_status"] = 2;
              y["dpr_id_date"] = "";
              y["dpr_value_old"] = "";
              // console.log(y, "fsfdsf");

              array.map((x) => {
                if (newArrayCheck.length > 0) {
                  let check = newArrayCheck.indexOf(x);
                  if (check >= 0) {
                    y[x] = "";
                  } else {
                    y[x] = "-";
                  }
                } else {
                  y[x] = "";
                }

                if (this.dprlist.length > 0 && y[x] != "-") {
                  let arrayAppendValue = this.dprlist.filter((z) => {
                    return z.type_date == x && z.budget_id == y.budget_id;
                  });
                  //  console.log(arrayAppendValue);

                  if (arrayAppendValue.length > 0) {
                    y["dpr_value"] = arrayAppendValue[0]["dpr_value"];

                    y["total_budget"] =
                      parseFloat(y["total_budget"]) +
                      parseFloat(arrayAppendValue[0]["dpr_value"]);

                    y[x] = arrayAppendValue[0]["dpr_value"];
                    y[x + "_dpr_id"] = arrayAppendValue[0]["dpr_id"];

                    y[x + "_save_status"] = arrayAppendValue[0]["save_status"];
                    y["dpr_id_date"] = arrayAppendValue[0]["dpr_id"];
                  } else {
                    y[x + "_dpr_id"] = "";
                    y[x + "_save_status"] = "";
                  }
                } else {
                  y[x + "_dpr_id"] = "";
                  y[x + "_save_status"] = "";
                }
                if (index == 0) {
                  let obj = {
                    headerName: x,
                    field: x,
                    filter: false,
                    width: 200,

                    editable: this.cellEditorDisable,
                    cellEditor: "numericCellEditor",
                    cellRendererFramework: dprFInalSaveVue,
                  };
                  this.columnDefs.push(obj);
                }
              });
              if (index == 0) {
                let objNew = {
                  headerName: "Total Budget",
                  field: "total_budget",
                  filter: false,
                  width: 200,
                  editable: false,
                  suppressCellFlash: true,
                  cellRenderer: function (params) {
                    return `<span class="font-bold">${params.data.total_budget.toLocaleString(
                      "en-IN"
                    )}</span>`;
                  },
                };
                this.columnDefs.push(objNew);
              }
            });
            this.array_check = {};

            Object.keys(this.budgetList[0]).map((x, index) => {
              if (x.includes("-")) {
                this.array_check[x] = "button";
              } else {
                this.array_check[x] = "";
              }
            });
            this.array_check["project_id"] = this.budgetList[0].project_id;
            this.array_check["start_date"] = this.budgetList[0].start_date;
            this.array_check["end_date"] = this.budgetList[0].end_date;
            this.budgetList.unshift(this.array_check);

            this.highestTypeDate = data.data.highestTypeDate;
            this.lowestTypeDate = data.data.lowestTypeDate;

            // this.getArrayDate = this.getDates(
            //   this.lowestTypeDate,
            //   this.highestTypeDate
            // );
            this.getArrayDate = array;
            this.$vs.loading.close();

            // this.getExcel("1");

            // this.gridApi.sizeColumnsToFit();
          } else {
            this.budgetList = [];
            this.$vs.loading.close();
            this.showExcelButton = false;
            if(this.countError==0){
                 this.$vs.notify({
              title: "Error",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            this.countError=this.countError+1

            }

          }

          // this.gridApi.sizeColumnsToFit();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // getExcel(id) {
    //   if (id == null) {
    //     this.json_data = [];
    //   }

    //   //console.log(this.formpayload);
    //   // let filter = {
    //   //   fromDate: this.lowestTypeDate,
    //   //   toDate: this.highestTypeDate,
    //   //   project_id: this.formpayload.project_id,
    //   //   page: "0"
    //   // };
    //   let filter = {
    //     fromDate: this.formpayload.fromDate,
    //     toDate: this.formpayload.toDate,
    //     project_id: this.formpayload.project_id,
    //     page: "0"
    //   };
    //   if (this.userLogin.includes("Admin")) {
    //     filter["saveStatus"] = 1;
    //   }
    //   console.log(filter, "sfdsf");

    //   // console.log(filter);
    //   // return false;
    //   DPRService.getDprList(filter)
    //     .then(response => {
    //       const { data } = response;
    //       //  console.log(data);

    //       if (data.Status == false) {
    //       } else {
    //         this.json_data = data.data.budgetRecords.data;
    //         this.dprlist_data = data.data.dprRecords;

    //         this.json_data.map((y, index) => {
    //           let newArrayCheck = [];
    //           if (y.budget_start_date == "" || y.budget_start_date == null) {
    //             y["start_date"] = "";
    //             y["end_date"] = "";
    //           } else {
    //             y["start_date"] = y.budget_start_date;
    //             y["end_date"] = y.budget_end_date;
    //             newArrayCheck = this.getDates(
    //               new Date(y.budget_start_date),
    //               new Date(y.budget_end_date)
    //             );
    //           }
    //           let count = this.getArrayDate.length - 1;
    //           y["start_date1"] = filter.fromDate1;
    //           y["end_date1"] = filter.toDate1;

    //           y["total_budget"] = 0;
    //           y["dpr_reason"] = "";

    //           y["save_status"] = 2;
    //           y["dpr_id_date"] = "";
    //           y["dpr_value_old"] = "";

    //           this.getArrayDate.map(x => {
    //             if (newArrayCheck.length > 0) {
    //               let check = newArrayCheck.indexOf(x);
    //               if (check >= 0) {
    //                 y[x] = "";
    //               } else {
    //                 y[x] = "-";
    //               }
    //             } else {
    //               y[x] = "";
    //             }

    //             if (this.dprlist_data.length > 0 && y[x] != "-") {
    //               let arrayAppendValue = this.dprlist_data.filter(z => {
    //                 return z.type_date == x && z.budget_id == y.budget_id;
    //               });
    //               //  console.log(arrayAppendValue);

    //               if (arrayAppendValue.length > 0) {
    //                 y["dpr_value"] = arrayAppendValue[0]["dpr_value"];

    //                 y["total_budget"] =
    //                   parseFloat(y["total_budget"]) +
    //                   parseFloat(arrayAppendValue[0]["dpr_value"]);

    //                 y[x] = arrayAppendValue[0]["dpr_value"];
    //                 y[x + "_dpr_id"] = arrayAppendValue[0]["dpr_id"];

    //                 // y["save_status"] = arrayAppendValue[0]["save_status"];
    //                 y["dpr_id_date"] = arrayAppendValue[0]["dpr_id"];
    //               } else {
    //                 y[x + "_dpr_id"] = "";
    //               }
    //             } else {
    //               y[x + "_dpr_id"] = "";
    //             }

    //             if (index == 0) {
    //               this.json_field[x] = x;
    //             }
    //           });

    //           // if (index == 0) {
    //           //   this.json_field["Total BUdget"] = "total_budget";
    //           // }
    //         });

    //         this.showExcelButton = true;

    //         // this.gridApi.sizeColumnsToFit();
    //       }

    //       // this.gridApi.sizeColumnsToFit();
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }
  },

  mounted() {
    //this.popupActiveTest=true
    // if (this.checkPermission(["currency", "GET"])) {
    //   this.getCurrencyList(this.currentPage);
    // }

    if (!this.userLogin.includes("Admin")) {
      delete this.json_field["Budget"];
    }
    eventBus.$on("refreshTableDataUpdate", (e) => {
      // console.log("fsdfsdf");
      this.budgetList.map((x) => {
        // console.log(x);
        Object.keys(x).map((y) => {
          if (x[y] != "" && y == e && x[y] != "-" && !x[y].includes("button")) {
            console.log(x[y], y, e);

            x[y + "_save_status"] = "1";
          }
        });
      });
      this.gridOptions.api.refreshView();
    });

    eventBus.$on("refreshTableStartDataUpdate", (e) => {
      this.budgetList[e.index]["total_budget"] = 0;
      console.log(this.budgetList[e.index]);
      Object.keys(this.budgetList[e.index]).map((x) => {
        if (
          x.includes("-") &&
          !x.includes("_dpr_id") &&
          !x.includes("_save_status")
        ) {
          let index = e.dateArray.indexOf(x);
          console.log(e.dateArray, index, x);
          if (index < 0) {
            this.budgetList[e.index][x] = "-";
          } else {
            if (this.dprlist.length > 0) {
              let arrayAppendValue = this.dprlist.filter((z) => {
                return (
                  z.type_date == x &&
                  z.budget_id == this.budgetList[e.index].budget_id
                );
              });
              if (arrayAppendValue.length > 0) {
                this.budgetList[e.index]["total_budget"] =
                  parseFloat(this.budgetList[e.index]["total_budget"]) +
                  parseFloat(arrayAppendValue[0]["dpr_value"]);
                this.budgetList[e.index][x] = arrayAppendValue[0]["dpr_value"];
              } else {
                this.budgetList[e.index][x] = "";
              }
            } else {
              this.budgetList[e.index][x] = "";
            }
          }
          if (e.typeFrom == "start_date") {
            this.budgetList[e.index]["start_date"] = e.fromdate;
          } else {
            this.budgetList[e.index]["end_date"] = e.toDate;
          }

          this.budgetList[e.index]["dpr_id"] = e.dpr_id;
        }
      });

      // console.log("fsdfdsf");

      this.gridOptions.api.refreshView();
    });
    // console.log("fsdfsdf");
    //   this.budgetList.map(x => {
    //     // console.log(x);
    //     Object.keys(x).map(y => {
    //       if (x[y] != "" && y == e) {
    //         x[y + "_save_status"] = "1";
    //       }
    //     });
    //   });
    // });
    this.gridApi = this.gridOptions.api;
  },
};
</script>
<style>
input::-webkit-outer-spin-button, /* Removes arrows */
input::-webkit-inner-spin-button, /* Removes arrows */
input::-webkit-clear-button {
  /* Removes blue cross */
  -webkit-appearance: none;
  margin: 0;
}
</style>

<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->
<template>
  <div>
    <!-- DPR FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Daily Cost Report">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Project Name</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div>
                      <multiselect
                        v-model="form.projectSelected"
                        track-by="project_id"
                        label="project_name"
                        :options="projects"
                        name="Project Name"
                        placeholder="Select Project"
                        :searchable="true"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.project_name }}
                        </template>
                      </multiselect>
                      <p class="error-msg">
                        <span
                          class="text-danger text-sm"
                          v-show="errors.has('Project Name')"
                          >{{ errors.first("Project Name") }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Select Date</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class="d-flex align-items-center">
                      <div class="w-100 mr-2">
                        <datepicker
                          placeholder="From Date"
                          @selected="onSelectDate($event)"
                          format="dd-MM-yyyy"
                          v-model="form.dateFrom"
                        ></datepicker>
                        <!-- :disabledDates="startDisabledDates"-->
                      </div>
                      <div class="w-100 ml-2">
                        <datepicker
                          placeholder="To Date"
                          :disabledDates="endDisabledDates"
                          v-model="form.dateTo"
                          format="dd-MM-yyyy"
                          :disabled="toDateDisable"
                        ></datepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";
import axios from "axios";

import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { TokenService } from "@/services/storage.service.js";
import CurrencyService from "@/services/currencyService";
import ProjectService from "@/services/projectService";
const VxTour = () => import("@/components/VxTour.vue");

export default {
  components: {
    AgGridVue,
    Datepicker,
  },
  data() {
    return {
      excelurl: BASEURL,
      submitStatus: true,
      gridApi: null,
      projectSelected: [],
      projects: [],
      startDisabledDates: {
        from: new Date(),
      },
      endDisabledDates: {
        from: null,
        to: new Date(),
      },
      form: {
        projectSelected: "",
        dateFrom: "",
        dateTo: "",
      },
      toDateDisable: true,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    onSelectDate: function ($event) {
      this.endDisabledDates["to"] = new Date(moment(new Date($event)).format());
      this.form.dateTo = new Date($event);
      this.toDateDisable = false;
    },
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },
    //  getAllProjects
    getProjectsList: function () {
      ProjectService.getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submitForm() {
      this.$validator.validateAll().then((result) => {
        // console.log("asfsafsasfs");
        if (result) {
          // this.$vs.loading();
          // this.submitStatus = false;
          let payload = {
            fromDate: moment(this.form.dateFrom).format("DD-MM-YYYY"),
            toDate: moment(this.form.dateTo).format("DD-MM-YYYY"),
            project_id: this.form.projectSelected.project_id,
            fromDate1: this.form.dateFrom,
            toDate1: this.form.dateTo,
          };

          eventBus.$emit("refreshTableDPR", payload);
        } else {
          // form have errors
        }
      });
    },

    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.description = "";
      this.value = [];
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    // this.gridApi = this.gridOptions.api;
    this.getProjectsList();
  },
};
</script>
